::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: $text-font-stack;
  font-size: 15px;
  color: $placehoder-color;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: $text-font-stack;
  font-size: 15px;
  color: $placehoder-color;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-family: $text-font-stack;
  font-size: 15px;
  color: $placehoder-color;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-family: $text-font-stack;
  font-size: 15px;
  color: $placehoder-color;
}

#root {
  font-family: $text-font-stack;
  height: 100%;
  margin: 0px;
}

body {
  font-family: $text-font-stack;
  background-color: #184678;
  font-size: 24px;
  color: $reg-white;
}

a {
  color: $reg-white;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: $reg-white;
  background-color: $brand-color;
  border-color: $transparent-color;
  width: 100%;
  border-radius: 5px;
  color: $reg-white;
  //height: 48px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
}

.btn-primary:hover {
  background-color: $brand-color-on-hover;
  border-color: $transparent-color;
}

.btn-primary:focus {
  background-color: $brand-color;
  border-color: $transparent-color;
}

.btn-primary:active {
  background-color: $brand-color;
  border-color: $transparent-color;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $placehoder-color;
  border-color: $transparent-color;
}

.reg-bold-64 {
  font-weight: 600;
  font-size: 64px;
}

.reg-bold-55 {
  font-weight: 600;
  font-size: 45px;
  margin-bottom: 10px;
}

.reg-bold-24 {
  font-weight: 600;
  font-size: 24px;
}

.reg-medium-20 {
  font-weight: 500;
  font-size: 20px;
}

.reg-medium-italic-20 {
  font-weight: 500;
  font-style: italic;
  font-size: 20px;
}

.reg-regular-40 {
  font-size: 40px;
}

.lbl-contact-us-subtitle {
  font-size: 26px;
}

.reg-regular-default {
  font-size: 20px;
}

.reg-regular-15-grey {
  font-size: 15px;
  color: $placehoder-color;
}

.reg-regular-13-grey {
  font-size: 13px;
  color: $placehoder-color;
}

.reg-regular-12-grey {
  font-size: 13px;
  color: $placehoder-color;
}

.reg-light-20 {
  font-weight: 100;
  font-size: 20px;
}

.reg-regular-11-red {
  font-size: 13px;
  color: $reg-red;
}

.margin-top-90 {
  margin-top: 90px;
}

.margin-top-84 {
  margin-top: 84px;
}

.margin-top-64 {
  margin-top: 72px;
}

.margin-top-57 {
  margin-top: 57px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-45 {
  margin-top: 45px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-28 {
  margin-top: 28px;
}

.margin-top-20 {
  margin-top: 20px;
}

.top-content {
  background-image: url("/images/main-background.jpg");
  -webkit-background-size: auto;
  -moz-background-size: auto;
  -o-background-size: auto;
  background-size: cover;
  padding-bottom: 20px;
}

.subscribe-pcfa-news {
  background-color: #184678;
  padding-bottom: 60px;
  padding-top: 50px;
  margin-left: 0px;
  margin-right: 0px;
}

.about-pcfa-content {
  background-color: #2b3442;
  padding-bottom: 30px;
  padding-top: 00px;
}

.stargate-section .about-pcfa-content {
  background-color: #2b3442;
  padding-top: 30px;
  padding-bottom: 30px !important;
}

.img-about-stargate-desktop {
  text-align: center;
  padding-left: 0px !important;
  margin-left: 0px !important;
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.img-about-stargate-mobile {
  text-align: center;
  padding-left: 0px !important;
  margin-left: 0px !important;
  img {
    width: 100% !important;
    height: auto !important;
  }
}

.img-about-pcfa-desktop {
  text-align: center;
  margin-top: 20px;
  padding-left: 0px;
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.img-about-pcfa-mobile {
  text-align: center;
  margin-top: 20px;
  padding-left: 0px;
  img {
    width: 100% !important;
    height: auto !important;
  }
}

.contact-us-content {
  background-color: #184678;
  padding-bottom: 150px;
  margin-top: 60px;
}

.donate-content {
  background-color: gray;
}

.footer-content {
  background-color: $reg-white;
  padding-top: 35px;
  padding-bottom: 35px;
}

.topnav {
  margin-top: 10px;
  height: 70px;
}

.topnav-right {
  float: right;
  height: 70px;
}

.float-right {
  float: right;
}

.nav-item {
  margin-top: auto;
  margin-right: 30px;
}

.navbar-nav > .nav-item > a:hover,
.navbar-nav > .nav-item > a:focus {
  text-decoration: none;
  color: $reg-white !important;
}

.nav-donate-item {
  margin-top: auto;
  margin-right: 0px;
}

.navbar {
  padding: 0px;
}

.div-width-100 {
  width: 100%;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 25 25' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border-width: 0px;
  padding-left: 0px;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 20px;
  color: $reg-white;
}

.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}

.padding-zero {
  padding: 0px;
}

.btn-default {
  border-radius: 25x;
  background-color: $brand-color;
  font-size: 20px;
}

.btn-donate {
  border-radius: 20px;
  background-color: $brand-color;
  font-size: 20px;
  height: 48px;
  width: 120px;
}

.container {
  max-width: 1920px;
  justify-content: center;
}

.recapcha-container > div > div > div {
  width: 100% !important;
}

.searched-result-container {
  height: 95px;
  color: $reg-white;
  line-height: 35px;
  font-weight: 400;
  font-style: italic;
  font-size: 30px;
  color: #fff !important;
}

.searched-result-bg-container {
  background: rgba(24, 70, 120, 0.6);
  max-width: 860px;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 48px;
  z-index: 1;
  padding-left: 40px;
  padding-right: 40px;
}

.searched-result-bg-container hr {
  border: 3px solid $reg-white;
  margin-top: 13px;
  margin-bottom: 17px;
}

.contact-us-content hr {
  border: 3px solid $reg-white;
  margin-top: 13px;
  margin-bottom: 17px;
}

.search-box-bgcolor {
  background-color: $reg-white;
}

.search-box-border {
  border: 0px;
}

.sidebar {
  position: fixed;
  left: -100vw;
  height: 100vh;
  width: 100vw;
  height: 100%;
  transition: left 0.3s ease-in-out;
  background-color: $reg-white;
  color: black;
  z-index: 2;
  padding: 15px;
}

.sidebar.open {
  left: 0;
  top: 0;
}

.sidebar .navbar-nav .nav-link {
  font-size: 20px;
  color: #000000;
}

.sidebar .navbar-nav .nav-item {
  margin-right: 0px;
}

.sidebar .navbar-nav .nav-item hr {
  border-top: 2px solid #b3b3b3;
}

.sidebar .navbar-nav .nav-link:active {
  color: #000000 !important;
}

.fa-icon-20 {
  font-size: 16px;
  margin-right: 5px;
  vertical-align: middle;
  margin-bottom: 2px;
}

.fa-icon-30 {
  font-size: 30px;
  margin-right: 5px;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-top: 7px;
  cursor: pointer;
}

.form-control {
  border-radius: 5px;
  height: 48px;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-open-website {
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.btn-social {
  padding: 7px;
  cursor: pointer;
}

.modal-header {
  border-bottom: 0px;
}

.popup-modal-title {
  font-size: 24px;
  color: #000000;
  padding-left: 15px;
}

.popup-modal-body {
  font-size: 18px;
  color: #000000;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-top: 0px;
}

.lbl-or {
  margin-top: 20px;
  margin-bottom: 20px;
}

.search-result {
  position: absolute;
  color: #000000;
  width: 500px;
  margin-top: 54px;
  margin-left: 10px;
  background-color: $reg-white;
  text-align: left;
  padding: 15px;
  z-index: 2;
}

.search-result-item {
  font-size: 18px;
  padding-bottom: 5px;
}

.search-result-item:hover {
  background-color: $reg-white;
}

.row-input-height {
  height: 70px;
}

.row-textarea-height {
  height: 230px;
}

.spinner-border-sm {
  margin-right: 10px;
}

.toast-custom {
  position: fixed;
  margin-bottom: 10px;
  margin-right: 10px;
  z-index: 2;
}

.toast-body-custom {
  color: $reg-red;
  font-size: 12px;
}

.pac-target-input {
  font-family: $text-font-stack;
  font-size: 25px;
  width: 80%;
  height: 40px;
  border: 0px solid;
  outline: none;
  color: #495057;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: silver;
    font-size: 18px;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: silver;
    font-size: 18px;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: silver;
    font-size: 18px;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: silver;
    font-size: 18px;
  }
}

.top-sub-menu-slideup,
.top-sub-menu-slidedown {
  max-height: 0;
  overflow-y: hidden;
  -webkit-transition: max-height 0.3s ease-in-out;
  -moz-transition: max-height 0.3s ease-in-out;
  -o-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
}

.top-sub-menu-slidedown {
  max-height: 150px;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  background-color: $brand-color;
  color: white;
  padding: 0px;
  width: 50px;
}

.scroll-to-top-fa-icon {
  font-size: 23px;
}

.pac-container:after {
  display: none !important;
}

@media (min-width: 1056px) {
  // Top menu
  .top-nav-menu-logo {
    display: block;
    img {
      width: 240px;
    }
  }
  .top-nav-menu {
    display: block;
  }
  .top-nav-bugger-menu {
    display: none;
  }
}

@media (max-width: 1055px) {
  // Top menu
  .top-nav-menu-logo {
    display: none;
  }
  .top-nav-menu {
    display: none;
  }
  .top-nav-bugger-menu {
    display: block;
  }
}

@media (min-width: 576px) {
  .top-content {
    padding-top: 30px;
  }

  .container {
    padding-left: 50px;
    padding-right: 50px;
  }

  .search-container-top {
    margin-top: 72px;
  }

  .search-container {
    background-color: $reg-white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
    min-width: 300px;
    display: inline-flex;
  }

  .searched-area {
    font-size: 48px;
  }

  .section-title {
    font-weight: 600;
    font-size: 34px;
    margin-bottom: 20px;
    color: #0080ba;
  }

  .search-fa-icon {
    font-size: 40px;
    padding-bottom: 5px;
    margin-right: 10px;
    margin-top: 6px;
  }

  .prostate-cancer-support-container {
    max-width: 639px;
    margin: 0 auto;
  }

  .contact-us-sub-content {
    max-width: 534px;
    margin: 0 auto;
  }

  .contact-us-sub-content-hr {
    margin-top: 84px;
  }

  .contact-us-sub-content-title-top {
    font-size: 26px;
    margin-top: 8px;
    margin-bottom: 5px;
  }

  .contact-us-sub-content-title {
    font-size: 26px;
    margin-top: 37px;
    margin-bottom: 5px;
  }

  .contact-us-sub-content-body {
    font-size: 16px;
  }

  .error-message {
    font-size: 11px;
    color: $reg-red;
    text-align: start;
  }
}

@media (max-width: 576px) {
  body {
    font-size: 15px;
  }

  .top-content {
    padding-top: 20px;
  }

  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .search-container-top {
    margin-top: 53px;
  }

  .section-title {
    font-weight: 600;
    font-size: 24px;
    color: #0080ba;
  }

  .search-container {
    background-color: $reg-white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
    width: 300px;
    display: inline-flex;
  }

  .about-pcfa-content {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
  }

  .prostate-cancer-support-container {
    width: 100%;
  }

  .contact-us-sub-content {
    width: 100%;
    padding-top: 15px;
  }

  .contact-us-sub-content-hr {
    margin-top: 30px;
  }

  .contact-us-sub-content-title-top {
    font-size: 24px;
    margin-top: 15px;
  }

  .contact-us-sub-content-title {
    font-size: 24px;
    margin-top: 30px;
  }

  .contact-us-sub-content-body {
    font-size: 15px;
  }

  .recapcha-container {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .subscribe-pcfa-news {
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .footer-content {
    height: auto;
    padding-bottom: 50px;
  }

  .searched-result-container {
    line-height: 25px;
    font-size: 18px;
  }

  .search-fa-icon {
    font-size: 26px;
    padding-bottom: 0px;
    margin-top: 6px;
  }

  .searched-area {
    font-size: 24px;
  }

  .reg-regular-default {
    font-size: 15px;
  }

  .searched-result-bg-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .contact-us-content {
    margin-top: 40px;
  }

  .lbl-contact-us-subtitle {
    font-size: 20px;
  }

  .searched-result-bg-container hr {
    border: 2px solid $reg-white;
  }

  .contact-us-content hr {
    border: 2px solid $reg-white;
  }

  .contact-us-content {
    padding-bottom: 40px;
  }

  .error-message {
    font-size: 12px;
    color: $reg-red;
    text-align: start;
  }

  .search-result {
    width: 83%;
    margin-top: 54px;
    background-color: $reg-white;
    text-align: left;
    padding: 10px;
  }

  .search-result-item {
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .row-input-height {
    height: auto;
  }

  .row-textarea-height {
    height: auto;
  }
}

.search-container input {
    border-width: 0 !important;
    outline: none;
    height:34px;
    font-size:18px;
}

.search-container input:focus {
    border-width: 0 !important;
    outline: none;
    height: 34px;
    font-size: 18px;
}

.search-container > .input-group > div:nth-child(2) > div {
    margin-left:-40px;
    width:260px;
}