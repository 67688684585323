/// Font family
/// @type List
$text-font-stack: "HelveticaNeue", "sans-serif" !default;

/// Copy text color
/// @type Color
$text-color: white;

/// Main brand color
/// @type Color
//$brand-color: #ffc001;
$brand-color: #f99d33;

/// Main brand color - on hover
/// @type Color
$brand-color-on-hover: #ae7637;

/// Placehoder color
/// @type Color
$placehoder-color: #777777;

/// Transparent color
/// @type Color
$transparent-color: transparent;

//Colors //
$reg-white: #ffffff !default;

$reg-black: #000000 !default;

$reg-red: red !default;
